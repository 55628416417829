import React from 'react'
import { Box } from '@mui/material'

export const Form = ({ children, handleSubmit }) => {
  return (
    <Box component="form" noValidate autoComplete="off" onSubmit={handleSubmit}>
      {children}
    </Box>
  )
}
