'use client'
import React from 'react'
import styled from 'styled-components'

import WidthContainer from '../../elements/widthContainer'
import { innerHtml } from '../../utils/innerHtml'

const Wrapper = styled.div`
  display: block;
  width: calc(100% - 340px);
  margin-bottom: 20px;

  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`

const ArticleRichText = ({ text }) => {
  return (
    <WidthContainer variant="semi">
      <Wrapper>{innerHtml(text)}</Wrapper>
    </WidthContainer>
  )
}

export default ArticleRichText
