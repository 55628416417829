import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../elements/typography'

import SvgElement from '../../elements/svg'
import CheckIcon from '@mui/icons-material/Check'
import { grey, primary_green } from '../../colors'

const Container = styled.div`
  padding: 50px 80px 49px 64px;
  border: 1px solid ${grey};
  .svgContainer {
    display: inline-block;
    vertical-align: middle;
    width: 125px;
    margin-right: 66px;
  }
  .textContainer {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 125px - 66px);
  }

  @media screen and (max-width: 1200px) {
    padding: 40px;
  }

  @media screen and (max-width: 740px) {
    .svgContainer,
    .textContainer {
      width: 100%;
    }
  }
`

const SuccessBlock = ({
  title = 'Evenement succesvol aangemeld!',
  text = 'Het evenement is succesvol aangemeld. We zullen het evenement beoordelen en binnen 2 dagen ontvangt u een e-mail met goedkeuring',
}) => {
  return (
    <Container>
      <div className="svgContainer">
        <CheckIcon sx={{ fontSize: 125, color: primary_green }} />
      </div>
      <div className="textContainer">
        <TypographyElement variant="title" component="h3">
          {title}
        </TypographyElement>
        <TypographyElement variant="p" component="p">
          {text}
        </TypographyElement>
      </div>
    </Container>
  )
}

export default SuccessBlock
