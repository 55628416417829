import React from 'react'
import styled from 'styled-components'
import { nl } from 'date-fns/locale'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import TypographyElement from '../../elements/typography'
import Field, { FormRow } from '../../elements/fields'
import { Form } from '../../elements/form'
import ButtonElement from '../../elements/buttons'
import { red } from '../../colors'
import CSRFToken from '../../elements/csrf'
import { postCustomFormApi } from '../../utils/api/fetchApi'
import { FormHelperText } from '@mui/material'
import { innerHtml } from '../../utils/innerHtml'
import SuccessSection from '../../sections/successSection'
import { uid } from '@/core/utils/uid'

const FormWrapper = styled.div`
  max-width: 761px;
  margin-bottom: 70px;
  .subform {
    display: block;
    margin-top: 40px;
  }
  .buttons {
    > * {
      display: inline-flex;
      vertical-align: middle;
      width: 50%;
      margin-top: 16px;
      :first-child {
        text-align: left;
      }
      :last-child {
        text-align: right;
      }
    }
    b {
      display: inline;
      margin-right: 1rem;
    }
  }
  .image-preview {
    display: inline-block;
    height: 100px;
    width: 150px;
    margin-right: 20px;
    margin-top: 20px;
    position: relative;
    span {
      height: 100px;
      width: 150px;
      img {
        object-position: center;
        object-fit: contain;
      }
    }
    &.multi {
      display:block;
      width: unset;
      height: unset;
    }
    .remove {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 20px;
      font-weight: 700;
      padding: 10px;
      color: ${red};
      cursor: pointer;
    }
  }
`

const FormBuilder = ({
  form,
  id,
  text,
  title,
  // host,
}) => {
  const [processing, setProcessing] = React.useState(false)
  const [selectedFile, setSelectedFile] = React.useState()
  const [selectedFiles, setSelectedFiles] = React.useState([])
  const [values, setValues] = React.useState({})
  const [errors, setErrors] = React.useState({})
  const [submitted, setSubmitted] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [host, setHost] = React.useState()
  const [submiterror, setSubmiterror] = React.useState('')

  const [requiredFieldNames, setRequiredFieldNames] = React.useState([])
  const [requiredDict, setRequiredDict] = React.useState({})

  const multiUploadField = React.useMemo(() => {
    return form.find(item => item.field_type === "multi_upload_doc")
  }, [form])

  // const requiredFields = form?.filter(item => item.required).map(field => field.name) || []
  React.useEffect(() => {
    const fields = form?.filter((item) => item.required && item.field_type != "multi_upload_doc") || []
    const fieldNames = []
    const fieldDict = {}
    fields.forEach((field) => {
      fieldNames.push(field.name)
      fieldDict[field.name] = field.label
    })
    setRequiredFieldNames(fieldNames)
    setRequiredDict(fieldDict)
  }, [form])

  React.useEffect(() => {
    if (window) {
      setHost(window.location.origin)
    }
  }, [])

  const { executeRecaptcha } = useGoogleReCaptcha()

  const handleChange = (e) => {
    const newState = { ...values }
    const { name, value } = e.target || {}
    newState[name] = value
    let errorkeys = Object.keys(errors)
    if (errorkeys && errorkeys.length > 0) {
      const errorState = handleValidationErrors(value, name)
      setErrors(errorState)
    }
    setValues(newState)
  }

  const getSortedState = (values, options) => {
    const selected = options.filter((option) => values.includes(option.value))
    return selected.map((item) => item.value)
  }

  const handleMultiChange = (e, options) => {
    const newState = { ...values }
    const { name, value } = e.target
    if (options) {
      const inputState = typeof value === 'string' ? value.split(',') : value
      const sortedState = getSortedState(inputState, options)
      newState[name] = sortedState
      let errorkeys = Object.keys(errors)
      if (errorkeys && errorkeys.length > 0) {
        const errorState = handleValidationErrors(sortedState, name)
        setErrors(errorState)
      }
      setValues(newState)
    } else {
      newState[name] = typeof value === 'string' ? value.split(',') : value
      let errorkeys = Object.keys(errors)
      if (errorkeys && errorkeys.length > 0) {
        const errorState = handleValidationErrors(value, name)
        setErrors(errorState)
      }
      setValues(newState)
    }
  }

  const handleTimeChange = (value, name) => {
    const newState = { ...values }
    newState[name] = value
    let errorkeys = Object.keys(errors)
    if (errorkeys && errorkeys.length > 0) {
      const errorState = handleValidationErrors(value, name)
      setErrors(errorState)
    }
    setValues(newState)
  }

  const handleDaterangeChange = (value, name) => {
    const newState = { ...values }
    newState[name] = value
    let errorkeys = Object.keys(errors)
    if (errorkeys && errorkeys.length > 0) {
      const errorState = handleValidationErrors(value, name)
      setErrors(errorState)
    }
    setValues(newState)
  }

  const handleFileChange = (e) => {
    e.preventDefault()
    setSelectedFile(e.target.files[0])
  }

  const handleMultiFileErrors = (error) => {
    const newErrors = {...errors}
    if (error) {
      newErrors["multi_upload_doc"] = error
    } else if ("multi_upload_doc" in newErrors) {
      delete newErrors["multi_upload_doc"]
    }
    setErrors(newErrors)
  }

  const validateMultiFiles = (files) => {
    if (!multiUploadField?.required) {
      return handleMultiFileErrors()
    }
    const { min_value: minValue, max_value: maxValue } = multiUploadField
    if (files?.length < minValue) {
      return handleMultiFileErrors(`Upload minimaal ${minValue} bestand(en)`)
    } else if (files?.length > maxValue) {
      return handleMultiFileErrors(`Upload maximaal ${maxValue} bestand(en)`)
    }
    return handleMultiFileErrors()
  }

  const handleMultiFileChange = (e) => {
    e.preventDefault()
    const files = Array.from(e.target.files).map(file => {
      // Add uid for remove functionality
      file.uid = uid()
      return file
    })
    const newFiles = [...files, ...selectedFiles]
    setSelectedFiles(newFiles)
    validateMultiFiles(newFiles)
  }

  const handleMultiRemoveFile = (file) => {
    if (!file?.uid) {
      return
    }
    const newFiles = selectedFiles.filter((item) => {
      return item.uid != file.uid
    })
    setSelectedFiles(newFiles)
    validateMultiFiles(newFiles)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (processing) {
      return
    }
    setProcessing(true)
    const csrftoken = e.target.csrfmiddlewaretoken.value
    const data = new FormData(e.target)
    const entries = [...data.entries()]
    const errorState = handleValidationErrorsSubmit(entries)
    setErrors(errorState)

    const token = await executeRecaptcha('FormSubmit')

    if (token && Object.keys(errorState).length == 0) {
      // handle api post
      postCustomFormApi(
        host,
        'api/formsubmit/',
        entries,
        id,
        email,
        csrftoken,
        token,
        data,
        selectedFile,
        selectedFiles
      )
        .then((res) => {
          if (res.success) {
            setSubmitted(true)
            setSubmiterror('')
            if (window) {
              window.scrollTo({ top: 0, behavior: 'smooth' })
            }
          } else {
            setSubmitted(false)
            setSubmiterror(res.error || res.message)
          }
          setProcessing(false)
        })
        .catch((e) => {
          console.error(e)
          setSubmiterror('Er is iets misgegaan!')
          setProcessing(false)
        })
    } else {
      setProcessing(false)
    }
  }

  const handleValidationErrors = (value, name) => {
    const newErrors = { ...errors }
    const nameInErrors = name in newErrors
    if (name in requiredFieldNames) {
      if (value) {
        if (nameInErrors) {
          // newErrors.filter(error => error != name)
          delete newErrors[name]
        }
      } else {
        if (!nameInErrors) {
          newErrors[name] = 'Verplicht!'
        }
      }
    } else {
      if (nameInErrors) {
        delete newErrors[name]
        // newErrors.filter(error => error != name)
      }
    }

    return newErrors
  }

  const handleValidationErrorsSubmit = (entries) => {
    const newErrors = {}
    requiredFieldNames.forEach((field) => {
      let hasValue = entries.some((entry) => {
        return entry[0] == field && entry[1]
      })
      if (!hasValue) {
        newErrors[field] = 'Verplicht!'
      }
    })
    return newErrors
  }

  const handleBlur = (e) => {
    const { value, name } = e.target
    const errorState = handleValidationErrors(value, name)
    setErrors(errorState)
  }

  const handleDateBlur = (name) => {
    if (!(name in values)) {
      return
    }
    const value = values[name]
    const errorState = handleValidationErrors(value, name)
    setErrors(errorState)
  }

  const disabled = errors && errors.length > 0

  const readableErrors =
    (Object.keys(errors).length &&
      Object.keys(errors).map((error) => {
        return requiredDict[error]
      })) ||
    []
  const displayErrors = readableErrors?.join(', ')

  const addRequiredField = (field) => {
    if (requiredFieldNames.includes(field)) {
      return
    }
    setRequiredFieldNames((prev) => [...prev, field])
  }

  const removeRequiredField = (field) => {
    if (!requiredFieldNames.includes(field)) {
      return
    }
    setRequiredFieldNames((prev) => prev.filter((item) => item != field))
  }

  return (
    <FormWrapper>
      {submitted ? (
        <SuccessSection
          successTitle="Formulier succesvol verzonden!"
          successText="Het formulier is succesvol verzonden. We zullen het ingezonden formulier beoordelen."
          backTo={null}
        />
      ) : (
        <Form handleSubmit={handleSubmit}>
          <LocalizationProvider adapterLocale={nl} dateAdapter={AdapterDateFns}>
            <CSRFToken />
            <div className="subform">
              <TypographyElement variant="title">{title}</TypographyElement>
              {innerHtml(text)}

              {form?.map((item, i) => {
                const standardprops = {
                  variant: item?.field_type,
                  label: item.label,
                  required: item.required,
                  value: values[item.name],
                  handleChange: handleChange,
                  id: item.name,
                  name: item.name,
                  handleBlur: handleBlur,
                  error:
                    errors && item?.name in errors ? errors[item.name] : '',
                  width: 'full',
                  description: item.help_text,
                  boldlable: true,
                  addRequiredField: addRequiredField,
                  removeRequiredField: removeRequiredField,
                }
                const key = `field-${1}-${item.name}`
                if (item.field_type == 'date') {
                  return (
                    <FormRow key={key}>
                      <Field
                        {...standardprops}
                        handleChange={(e, kbinput) => {
                          handleTimeChange(e, item.name)
                        }}
                        // minDate={null}
                        handleBlur={() => handleDateBlur(item.name)}
                      />
                    </FormRow>
                  )
                } else if (item.field_type == 'daterange') {
                  return (
                    <FormRow key={key}>
                      <Field
                        {...standardprops}
                        handleChange={(e) => {
                          handleDaterangeChange(e, item.name)
                        }}
                        // minDate={null}
                        handleBlur={() => handleDateBlur(item.name)}
                      />
                    </FormRow>
                  )
                } else if (item.field_type == 'time') {
                  return (
                    <FormRow key={key}>
                      <Field
                        {...standardprops}
                        handleChange={(e, kbinput) => {
                          handleTimeChange(e, item.name)
                        }}
                      />
                    </FormRow>
                  )
                } else if (item.field_type == 'select') {
                  return (
                    <FormRow key={key}>
                      <Field
                        {...standardprops}
                        options={item?.choices || []}
                        withEmpty={true}
                        value={values[item.name] || ''}
                        subchoicesError={errors[`${item.name}_subchoice`]}
                      />
                    </FormRow>
                  )
                } else if (item.field_type == 'multiselect') {
                  return (
                    <FormRow key={key}>
                      <Field
                        {...standardprops}
                        options={item?.choices || []}
                        withEmpty={true}
                        value={values[item.name] || []}
                        multiple={true}
                        handleChange={(e) =>
                          handleMultiChange(e, item?.choices || [])
                        }
                      />
                    </FormRow>
                  )
                } else if (item.field_type == 'email_target') {
                  return (
                    <FormRow key={key}>
                      <Field
                        {...standardprops}
                        variant="email"
                        handleChange={(e) => {
                          standardprops.handleChange(e)
                          setEmail(e.target.value)
                        }}
                      />
                    </FormRow>
                  )
                } else if (item.field_type == 'upload_doc') {
                  return (
                    <FormRow key={key}>
                      <Field
                        {...standardprops}
                        selectedFile={selectedFile}
                        variant="upload_doc"
                        handleChange={(e) => {
                          handleFileChange(e)
                        }}
                      />
                    </FormRow>
                  )
                } else if (item.field_type == 'multi_upload_doc') {
                  return (
                    <FormRow key={key}>
                      <Field
                        {...standardprops}
                        selectedFiles={selectedFiles}
                        min_value={item.min_value}
                        max_value={item.max_value}
                        variant="multi_upload_doc"
                        error={errors["multi_upload_doc"]}
                        handleChange={(e) => {
                          handleMultiFileChange(e)
                        }}
                        handleRemoveFile={handleMultiRemoveFile}
                      />
                    </FormRow>
                  )
                } else {
                  return (
                    <FormRow key={key}>
                      <Field {...standardprops} />
                    </FormRow>
                  )
                }
              })}
            </div>

            <div className="buttons">
              <ButtonElement
                variant="primary"
                disabled={disabled || processing}
                type="submit"
              >
                Formulier versturen
              </ButtonElement>
              {displayErrors && (
                <FormHelperText
                  error={errors ? true : false}
                  id="component-error-text"
                  sx={{
                    fontSize: 16,
                    textAlign: 'left !important',
                    width: '100% !important',
                  }}
                >
                  <b>Foutmelding: </b> Controleer de volgende velden:{' '}
                  {displayErrors}
                </FormHelperText>
              )}
              {submiterror && (
                <FormHelperText
                  error={submiterror ? true : false}
                  id="component-error-text"
                  sx={{
                    fontSize: 16,
                    textAlign: 'left !important',
                    width: '100% !important',
                  }}
                >
                  {submiterror}
                </FormHelperText>
              )}
            </div>
          </LocalizationProvider>
        </Form>
      )}
    </FormWrapper>
  )
}

export default FormBuilder
