import React from 'react'
import styled from 'styled-components'

import TypographyElement from '../../../elements/typography'
import ButtonElement from '../../../elements/buttons'

import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import {
  quarternary_black,
  red,
  search_border_grey,
  subsite_background,
  subsite_dark_background,
  white,
} from '../../../colors'
import { getMomentTz } from '../../../utils/getMomentTz'

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 36px;
  button {
    border: 0;
    padding: 0;
    width: 100%;
    display: block;
  }
  .dateContainer {
    width: 100%;
    display: flex;
    vertical-align: row;
    justify-content: space-between;
    align-items: center;
    padding: 19px 40px;
    background-color: ${subsite_dark_background};
  }
  .openingtimes {
    background-color: ${(props) => props.background};
    padding: 20px 50px;
    .openingrow {
      padding: 14px 0;
      .column {
        display: inline-block;
        vertical-align: top;
        width: 31%;
        margin-right: 2%;
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 20px;
        line-height: 30px;
        color: ${quarternary_black};

        &.bold {
          font-weight: 500;
        }
      }
      &.closed {
        > div {
          color: ${red} !important;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .openingtimes {
      .openingrow {
        .column {
          width: 52%;
          &.info {
            width: 100%;
            font-style: italic;
            font-size: 18px;
            margin-top: 6px;
          }
        }

        margin-bottom: 10px;
        border-bottom: 1px solid ${search_border_grey};
        :last-child {
          border: 0;
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    button {
      margin: 0px -20px;
      .dateContainer {
        width: calc(100% + 40px);
      }
    }

    .openingtimes {
      padding: 20px;
      margin: 0px -20px;

      .openingrow {
        .column {
          width: 100%;
          display: block;
          font-size: 16px;

          &.bold {
            font-weight: bold;
            font-size: 22px;
          }
        }
      }
    }
  }
`

const OpeningTimesBlock = ({
  date,
  timeslots,
  startOpen,
  whiteBackground = false,
  isActivities = false,
}) => {
  const [open, setOpen] = React.useState(startOpen)

  if (isActivities && timeslots?.length === 0) {
    return null
  }

  const displayDate = getMomentTz(date, 'DD-MM-YYYY').format('dddd D MMMM')

  const Icon = open ? ExpandLessRoundedIcon : ExpandMoreRoundedIcon
  return (
    <Wrapper background={whiteBackground ? white : subsite_background}>
      <ButtonElement handleClick={() => setOpen(!open)} variant="blank">
        <div className="dateContainer">
          <TypographyElement variant="newsCardTitle" color={white}>
            {displayDate}
          </TypographyElement>
          <Icon width={22} height={22} sx={{ color: white }} />
        </div>
      </ButtonElement>
      {open && (
        <div className="openingtimes">
          {timeslots?.map((row, index) => {
            return (
              <div
                key={`${index}-${row.start_tijd}-opening-time-check`}
                className={`openingrow${row.open ? '' : ' closed'}`}
              >
                <div className="column">
                  {row.start_tijd?.slice(0, 5)} - {row.eind_tijd?.slice(0, 5)}
                </div>
                <div className="column bold">{row.naam}</div>
                <div className="column info">
                  {row.extra_info} {!row.open && ' (gesloten)'}
                </div>
              </div>
            )
          })}
        </div>
      )}
    </Wrapper>
  )
}

export default OpeningTimesBlock
