'use client'
import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material'

import TypographyElement from '../../../elements/typography'
import ContactInfo from '../../../compositions/_subsite/contactInfo'
import { button_text_black } from '../../../colors'

const Wrapper = styled.div`
  padding: 94px 80px;
  margin-bottom: 70px;

  @media screen and (max-width: 1000px) {
    padding: 40px;
    margin-bottom: 40px;
  }

  background-color: ${(props) => props.background};
  border-radius: 5px;
  .textContainer {
    display: inline-block;
    vertical-align: middle;
    width: 68%;
    padding-right: 100px;

    @media screen and (max-width: 1000px) {
      width: 100%;
      padding: 0;
    }
  }
  .infoContainer {
    display: inline-block;
    vertical-align: middle;
    width: 32%;

    @media screen and (max-width: 1000px) {
      width: 100%;
      padding: 60px 0 0 0;
    }
  }

  a,
  span {
    font-weight: 500 !important;
  }
`

const ContactBlock = ({ text, telephone, email }) => {
  const theme = useTheme()
  const bg = theme?.globalTheme?.palette?.backgroundColor?.main || 'transparent'
  return (
    <Wrapper background={bg}>
      <div className="textContainer">
        <TypographyElement variant="eventBlockTitle" color={button_text_black}>
          {text.substring(0, 180)}
        </TypographyElement>
      </div>
      <div className="infoContainer">
        <ContactInfo telephone={telephone} email={email} />
      </div>
    </Wrapper>
  )
}

export default ContactBlock
