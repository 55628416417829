import React from 'react'
import styled from 'styled-components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import InfoIcon from '@mui/icons-material/Info'

import { blue, grey, grey_background, white } from '../../../colors'
import { innerHtml } from '../../../utils/innerHtml'

const Wrapper = styled.div`
  position: relative;
  .information {
    display: none;
  }
  &:hover {
    .information {
      display: block;
      position: absolute;
      bottom: 40px;
      left: -40px;
      background: ${white};
      padding: 19px 21px 20px 20px;
      box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05),
        0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054),
        0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
    }
  }
`

const InfoItem = ({ row }) => {
  return (
    <Wrapper>
      <div className="information">{innerHtml(row.info)}</div>
      <InfoIcon sx={{ color: blue, width: '26px', height: '26px' }} />
    </Wrapper>
  )
}

const PriceRow = ({ row }) => {
  return (
    <TableRow>
      <TableCell scope="row" isfirst="true">
        {row.title}
      </TableCell>
      <TableCell>{row.kid}</TableCell>
      <TableCell>{row.young}</TableCell>
      <TableCell>{row.adult}</TableCell>
      <TableCell>{row.elderly}</TableCell>
      <TableCell>{row.info && <InfoItem row={row} />}</TableCell>
    </TableRow>
  )
}

const PriceTable = ({ items }) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="a dense table">
        <TableHead
          variant="prices"
          sx={{
            backgroundColor: 'transparent',
            color: grey,
          }}
        >
          <TableRow>
            <TableCell sx={{ width: '30%' }}> </TableCell>
            <TableCell sx={{ width: '10%' }}>{'< 2 jaar'}</TableCell>
            <TableCell sx={{ width: '10%' }}>{'3-16 jaar'}</TableCell>
            <TableCell sx={{ width: '10%' }}>{'17-64 jaar'}</TableCell>
            <TableCell sx={{ width: '10%' }}>{'64+ jaar'}</TableCell>
            <TableCell sx={{ width: '30%' }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((row, i) => (
            <PriceRow row={row} index={i} key={`${i}-${row.title}`} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PriceTable
