import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import WidthContainer from '../../elements/widthContainer'
import FormBuilder from '../../modules/formbuilder'

const FormBuilderSection = (props) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6Let5mwkAAAAACyFq7CTgR8aVy3smEnkCcXCuEsO"
      language="nl"
    >
      <WidthContainer variant="semi">
        <FormBuilder {...props} />
      </WidthContainer>
    </GoogleReCaptchaProvider>
  )
}

export default FormBuilderSection
