'use client'
import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material'

import SubsiteNewsCard from '../../compositions/_subsite/newsCard'
import WidthContainer from '../../elements/widthContainer'

import image from '../../../stories/sections/assets/zwembad.png'
import PaginationElement from '../../elements/pagination'

const Container = styled.div`
  background: ${(props) => props.background};
  margin-bottom: 100px;
  .news {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .button {
    text-align: right;
    a {
      text-align: center;
    }

    @media screen and (max-width: 600px) {
      svg {
        display: none;
      }
    }
  }
`

const allnews = [
  {
    title: 'FC Groningen en Robey sportswear presentere, nieuwe dingen',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
  {
    title: '2',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
  {
    title: 'FC Groningen en Robey sportswear presentere, nieuwe dingen',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
  {
    title: 'FC Groningen en Robey sportswear presentere, nieuwe dingen',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
  {
    title: 'FC Groningen en Robey sportswear presentere, nieuwe dingen',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
  {
    title: 'FC Groningen en Robey sportswear presentere, nieuwe dingen',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
  {
    title: 'FC Groningen en Robey sportswear presentere, nieuwe dingen',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
  {
    title: 'FC Groningen en Robey sportswear presentere, nieuwe dingen',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
  {
    title: '9. FC Groningen en Robey sportswear presentere, nieuwe dingen',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
  {
    title: 'FC Groningen en Robey sportswear presentere, nieuwe dingen',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
  {
    title: '9. FC Groningen en Robey sportswear presentere, nieuwe dingen',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
  {
    title: 'FC Groningen en Robey sportswear presentere, nieuwe dingen',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
  {
    title: '9. FC Groningen en Robey sportswear presentere, nieuwe dingen',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
  {
    title: 'FC Groningen en Robey sportswear presentere, nieuwe dingen',
    date: new Date('2022-04-18T21:11:54'),
    category: '',
    href: '/',
    image: image,
  },
]

const getNews = (page, perPage) => {
  const start = (page - 1) * perPage
  const end = page * perPage
  const newNews = allnews.slice(start, end)
  return newNews
}

const SubsiteNewsSection = ({
  // news,
  hasBackground = true,
  perPage = 8,
  // totalCount,
}) => {
  const theme = useTheme()
  const [page, setPage] = React.useState(1)
  const [news, setNews] = React.useState([])

  React.useEffect(() => {
    const newNews = getNews(1, perPage)
    setNews(newNews)
  }, [])

  const totalCount = allnews?.length
  const pages = perPage ? Math.ceil(totalCount / perPage) : 0

  const handlePaginationChange = (e, value) => {
    const newNews = getNews(value, perPage)
    setPage(value)
    setNews(newNews)
    window?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <WidthContainer variant="full">
      <Container
        background={
          hasBackground
            ? theme?.globalTheme?.palette?.backgroundColor?.main
            : 'transparent'
        }
      >
        <div className="news">
          {news?.map((item, index) => {
            return (
              <SubsiteNewsCard
                key={`subsite-news-card-${index}`}
                {...item}
                isLarge={false}
              />
            )
          })}
        </div>

        {pages > 1 && (
          <PaginationElement
            count={pages}
            handleChange={handlePaginationChange}
          />
        )}
      </Container>
    </WidthContainer>
  )
}

export default SubsiteNewsSection
