import React from 'react'
import styled from 'styled-components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import InfoIcon from '@mui/icons-material/Info'

import { blue, grey, white } from '../../../colors'
import { innerHtml } from '../../../utils/innerHtml'
import TypographyElement from '../../../elements/typography'

const Wrapper = styled.div`
  position: relative;
  .information {
    display: none;
  }
  &:hover {
    .information {
      display: block;
      position: absolute;
      bottom: 40px;
      left: -40px;
      background: ${white};
      padding: 19px 21px 20px 20px;
      box-shadow: 0px 17px 98px rgba(0, 0, 0, 0.05),
        0px 3.79717px 21.8896px rgba(0, 0, 0, 0.0298054),
        0px 1.13052px 6.5171px rgba(0, 0, 0, 0.0201946);
    }
  }
`

const InfoItem = ({ info }) => {
  return (
    <Wrapper>
      <div className="information">{innerHtml(info)}</div>
      <InfoIcon sx={{ color: blue, width: '26px', height: '26px' }} />
    </Wrapper>
  )
}

const PriceRow = ({ row, firstColHeader, lastColumnIcon }) => {
  return (
    <TableRow>
      {row?.map((item, i) => {
        const isFirst = i === 0
        const isLast = i === row.length - 1
        return (
          <TableCell
            scope="row"
            isfirst={isFirst && firstColHeader ? 'true' : ''}
            key={`table-cell-${i + 1}`}
          >
            {isLast && item && lastColumnIcon ? <InfoItem info={item} /> : item}
          </TableCell>
        )
      })}
      <TableCell scope="row" isfirst="true">
        {row.title}
      </TableCell>
    </TableRow>
  )
}

const NewPriceTable = ({
  topRow,
  items,
  caption,
  lastColumnIcon = false,
  firstColHeader = true,
  firstRowHeader = true,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="a dense table">
        <TableHead
          variant="prices"
          sx={{
            backgroundColor: 'transparent',
            color: grey,
          }}
        >
          <TableRow>
            {topRow?.map((item, index) => {
              const n = topRow.length
              const fillWidth = 100 - 10 * n
              const width =
                index === 0 || index === n - 1 ? `${fillWidth}%` : '10%'
              return (
                <TableCell
                  sx={{
                    width: width,
                    fontWeight:
                      firstRowHeader || (firstColHeader && index === 0)
                        ? 600
                        : 400,
                  }}
                  key={`table-header-cell-${index + 1}`}
                >
                  {item}
                </TableCell>
              )
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {items?.map((row, i) => (
            <PriceRow
              row={row}
              index={i}
              key={`${i}-${row[0]}`}
              lastColumnIcon={lastColumnIcon}
              firstColHeader={firstColHeader}
            />
          ))}
        </TableBody>
      </Table>
      <TypographyElement variant="caption">{caption}</TypographyElement>
    </TableContainer>
  )
}

export default NewPriceTable
