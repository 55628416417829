'use client'
import React from 'react'
import styled from 'styled-components'
import { subsite_background } from '../../colors'
import ButtonElement from '../../elements/buttons'

import SubsiteTable from '../../modules/_subsite/table'
import { innerHtml } from '../../utils/innerHtml'

const Container = styled.div`
  margin-bottom: 66px;

  .tabButton {
    &:focus {
      display: inline-block;
      outline: 4px solid #000;
      z-index: 9999;
    }
  }

  @media screen and (max-width: 650px) {
    .tabButton {
      font-size: 16px;

      &:focus {
        display: inline-block;
        outline: 4px solid #000;
      }
    }
  }
`

const Wrapper = styled.div`
  overflow-x: scroll;
  ${(props) =>
    props.multi &&
    `
        padding: 59px 113px 18px;

        @media screen and (max-width: 850px) {
            padding: 30px 60px 18px;
        }

        @media screen and (max-width: 650px) {
            padding: 0;
            .wrapper {
                scale: 0.8;
            }
        }

        background-color: ${subsite_background}
    `};
  .contentBlock {
    margin-bottom: 80px;
    &.section,
    &.text {
      margin-bottom: 33px;
    }
  }
`

const SingleTable = ({ value, selected }) => {
  if (!selected) return null
  return <SubsiteTable title={value?.title} tables={value?.tables} />
}

const TextSection = ({ value }) => {
  return <div>{innerHtml(value)}</div>
}

const MapSection = {
  table: SingleTable,
  section: TextSection,
}

const SingleItemSection = ({ data, selected, multi }) => {
  return (
    <>
      {data?.data?.map((item, i) => {
        const Section = MapSection[item?.type]
        return (
          <Section
            {...item}
            selected={selected}
            multi={multi}
            key={`sectie-${i + 1}`}
          />
        )
      })}
    </>
  )
}

const TabWrapper = styled.div`
  display: inline-block;
  vertical-align: bottom;
  padding: 9px 33px;
  background-color: ${(props) =>
    props.selected ? subsite_background : '#C8E3EC'};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 200%;
`

const TabButton = ({ title, index, selected, setSelected }) => {
  const handleClick = () => {
    setSelected(index)
  }

  return (
    <ButtonElement
      className="tabButton"
      variant="blank"
      onClick={handleClick}
      sx={{
        padding: 0,
        margin: 0,
        border: 0,
        borderRadius: 0,
      }}
    >
      <TabWrapper selected={selected == index}>{title}</TabWrapper>
    </ButtonElement>
  )
}

const Rates = ({ data }) => {
  const [selected, setSelected] = React.useState(1)
  const single = data && data?.length <= 1

  return (
    <Container>
      {!single
        ? data?.map((item, i) => {
            return (
              <TabButton
                title={item.tab}
                index={i + 1}
                key={`tab-${i}-${item.tab}`}
                selected={selected}
                setSelected={setSelected}
              />
            )
          })
        : null}
      <Wrapper multi={!single}>
        <div className="wrapper">
          {data?.map((item, i) => {
            return (
              <SingleItemSection
                data={item}
                key={i}
                selected={i + 1 == selected}
                multi={!single}
              />
            )
          })}
        </div>
      </Wrapper>
    </Container>
  )
}

export default Rates
