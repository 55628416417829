import React from 'react'
import styled from 'styled-components'

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

import TypographyElement from '../../../elements/typography'
import ButtonElement from '../../../elements/buttons'
import { getMomentTz } from '../../../utils/getMomentTz'

const Wrapper = styled.div`
  margin-bottom: 28px;
  > * {
    display: inline-block;
    vertical-align: middle;
  }

  button {
    border: 0;
    span {
      line-height: 1;
    }
    margin: 0 18px;
    background-color: transparent;
  }
  @media screen and (max-width: 780px) {
    > button {
      margin: 0;
      padding: 6px 12px;
    }
    > div {
      width: calc(100% - 130px);
    }
  }
`

const WeekSelector = ({ week, handleChange }) => {
  const weekStart = getMomentTz(week.start).format('dd D MMMM')
  const weekEnd = getMomentTz(week.end).format('dd D MMMM')

  const handlePrev = () => {
    const newStart = getMomentTz(week.start).subtract(1, 'weeks').toString()
    const newEnd = getMomentTz(week.end).subtract(1, 'weeks').toString()
    const newWeek = {
      start: newStart,
      end: newEnd,
    }
    handleChange(newWeek)
  }
  const handleNext = () => {
    const newStart = getMomentTz(week.start).add(1, 'weeks').toString()
    const newEnd = getMomentTz(week.end).add(1, 'weeks').toString()
    const newWeek = {
      start: newStart,
      end: newEnd,
    }
    handleChange(newWeek)
  }
  const disabled = getMomentTz()
    .startOf('week')
    .isSameOrAfter(getMomentTz(week.start))
  return (
    <Wrapper>
      <ButtonElement
        handleClick={handlePrev}
        variant="blank"
        disabled={disabled}
      >
        <ChevronLeftIcon />
      </ButtonElement>

      <TypographyElement variant="weekSelector">
        {weekStart} t/m {weekEnd}
      </TypographyElement>

      <ButtonElement handleClick={handleNext} variant="blank">
        <ChevronRightIcon />
      </ButtonElement>
    </Wrapper>
  )
}

export default WeekSelector
