'use client'
import React from 'react'
import styled from 'styled-components'

import ActivityCard from '../../compositions/_subsite/activityCard'

const Wrapper = styled.div`
  display: block;
  max-width: 100%;
  margin-bottom: 85px;
  > *.condensedMode {
    display: inline-block;
    max-width: 50% !important;
    width: calc(50% - 4%) !important;
    max-width: unset !important;
    vertical-align: top;
    aspect-ratio: 0.97;
    margin-bottom: 35px;
    margin-right: 4%;

    @media screen and (max-width: 1400px) {
      max-width: 100% !important;
      width: calc(100%) !important;
      margin: 0 0 30px 0 !important;
    }
  }
`

const AllFacilities = ({ items }) => {
  return (
    <Wrapper>
      {items?.map((item, i) => {
        return (
          <ActivityCard
            {...item}
            condensedMode
            key={`activity-${i}-${item.text}`}
            grad="linear-gradient(0deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 34.87%)"
          />
        )
      })}
    </Wrapper>
  )
}

export default AllFacilities
