import React from 'react'
import styled from 'styled-components'
import { white } from '../../../colors'

import ButtonElement from '../../../elements/buttons'
import TypographyElement from '../../../elements/typography'
import SvgElement from '../../../elements/svg'
import ImageBuilder from '../../../elements/imageBuilder'

const Wrapper = styled.div`
  background: black;
  
  max-width: 33%;
  position: relative;
  
  &.condensedMode {
    height: 200px !important;
    margin-bottom: 30px !important;
    max-width: 25% !important;
    width: calc(25% - 30px) !important;

    @media screen and (max-width: 1400px) {
      max-width: 33% !important;
      width: calc(33% - 30px) !important;
    }

    @media screen and (max-width: 750px) {
      max-width: 50% !important;
      width: calc(50% - 30px) !important;
    }

    @media screen and (max-width: 630px) {
      max-width: 100% !important;
      width: calc(100%) !important;
      margin-right: 0 !important;
    }

    .textContainer {
      span {
        height: 42px;
        font-size: 20px !important;
      }
    }
  }

  .linkWrapper {
    width: 100%;
    height: 100%;
    display: block;
  }

  a {
    width: 100%;
    height: 100%;
    display: block;
  }

  > span > a {
    &:focus {
      outline: 4px solid #000 !important;
    }
  }
  .textContainer {
    position: absolute;
    padding: 23px 31px;
    bottom: 0;
    left: 0;
    right: 0;
    max-height: 100%;
    overflow: hidden;

    .titleContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .description {
      display: block;
      width: 100%;
      > p {
        line-height: 2;
      }
    }
  }

  .textarea,
  .mediaarea {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .mediaarea {
    span {
      height: inherit !important;
      img {
        object-fit: cover;
        object-position: center;
      }
    }
    .gradient {
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
    }
  }

  .textarea {
    z-index: 1;
    background: ${(props) => props.background};
    background-position: center center;
    background-size: cover;
  }
`

const ActivityCard = ({
  title,
  img,
  text,
  url,
  grad = null,
  search_img = null,
  condensedMode = false,
}) => {
  const gradient = grad
    ? grad
    : text
    ? 'linear-gradient(360deg, #2488A8 38.73%, rgba(36, 136, 168, 0) 100%)'
    : 'linear-gradient(0deg, rgba(36, 136, 168, 0.79) 0%, rgba(36, 136, 168, 0) 41.62%)'
  const background = gradient
  const image = search_img || img
  return (
    <Wrapper className={condensedMode ? 'condensedMode' : ''} background={background}>
      <ButtonElement variant="base" href={url} title={title}>
        {image && (
          <div className="mediaarea">
            <div className="gradient"></div>
            <ImageBuilder decorativeOnly={true} image={image} layout="fill" />
          </div>
        )}
        <div className="textarea">
          <div className="textContainer">
            <div className="titleContainer">
              <TypographyElement
                variant="eventBlockTitle"
                component="span"
                color={white}
              >
                {title}
              </TypographyElement>
              <SvgElement variant="arrowUpperRight" />
            </div>
            <div className="description">
              <TypographyElement variant="p" color={white}>
                {text}
              </TypographyElement>
            </div>
          </div>
        </div>
      </ButtonElement>
    </Wrapper>
  )
}

export default ActivityCard
