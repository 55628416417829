'use client'
import React from 'react'
import styled from 'styled-components'
import TypographyElement from '../../../elements/typography'
import NewPriceTable from '../../../compositions/_subsite/priceTable/new'
import ButtonElement from '../../../elements/buttons'

const Wrapper = styled.div`
  margin-bottom: 50px;
  .titleTab {
    > * {
      display: inline-block;
      vertical-align: middle;
    }
    > h3 {
      width: 380px;
    }
    .tabs {
      padding-left: 30px;
      width: calc(100% - 380px);
      white-space: nowrap;
    }
  }
`

const TabWrapper = styled.div`
  display: inline-block;
  vertical-align: bottom;
  button {
    span {
      ${(props) =>
        props.active
          ? `
                text-decoration: underline;
                font-weight: 600
            `
          : `
                text-decoration: none;
                font-weight: 400
            `}
    }
  }
`

const Tab = ({ tab, active, setActive }) => {
  return (
    <TabWrapper active={active === tab.id}>
      <ButtonElement
        variant="empty"
        handleClick={() => {
          setActive(tab.id)
        }}
      >
        <TypographyElement variant="clubDetails" component="span">
          {tab?.text}
        </TypographyElement>
      </ButtonElement>
    </TabWrapper>
  )
}

const SubsiteTable = ({ title, tables }) => {
  const [active, setActive] = React.useState(1)

  const tabs = tables
    ? tables?.map((item, i) => {
        return {
          text: item?.title,
          id: i + 1,
        }
      })
    : []
  const activeTable = tables ? tables[active - 1] : {}
  const items = activeTable?.table?.data ? [...activeTable.table.data] : []
  const topRow = items ? items[0] : []
  const rows = items?.slice(1) || []

  return (
    <Wrapper>
      <div className="titleTab">
        <TypographyElement variant="title" component="h3">
          {title}
        </TypographyElement>
        {tabs && tabs.length > 1 ? (
          <div className="tabs">
            {tabs?.map((tab) => {
              return (
                <Tab
                  tab={tab}
                  setActive={setActive}
                  active={active}
                  key={`tab-${tab.id}`}
                />
              )
            })}
          </div>
        ) : null}
      </div>

      <NewPriceTable
        items={rows}
        topRow={topRow}
        caption={activeTable?.table?.table_caption}
        lastColumnIcon={activeTable?.table?.last_column_info}
        firstColHeader={activeTable?.table?.first_col_is_header}
        firstRowHeader={activeTable?.table?.first_row_is_table_header}
      />
    </Wrapper>
  )
}

export default SubsiteTable
