'use client'
import React from 'react'
import styled from 'styled-components'
import TypographyElement from '../../../elements/typography'
import PriceTable from '../../../compositions/_subsite/priceTable'

const Wrapper = styled.div`
  margin-bottom: 50px;
`

export const SubsiteTable = ({ title, items }) => {
  return (
    <Wrapper>
      <TypographyElement variant="title">{title}</TypographyElement>
      <PriceTable items={items} />
    </Wrapper>
  )
}

const SubsiteProductPricesTable = ({ title, rows, items }) => {
  return (
    <Wrapper>
      <TypographyElement variant="title">{title}</TypographyElement>
      <PriceTable items={items} />
    </Wrapper>
  )
}

export default SubsiteProductPricesTable
