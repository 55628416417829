import React from 'react'
import styled from 'styled-components'
import Link from 'next/link'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import { useTheme } from '@mui/material'

import TypographyElement from '../../../elements/typography'
import SvgElement from '../../../elements/svg'
import ImageBuilder from '../../../elements/imageBuilder'
import { black, white } from '../../../colors'
import { secondary_green } from '../../../colors'
import { getMomentTz } from '../../../utils/getMomentTz'

const Card = styled.div`
  height: 410px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  /* width: 48%; */
  width: ${(props) => (props.small ? '32%' : '48%')};
  background: #fff;
  margin-bottom: 30px;

  @media screen and (max-width: 1200px) {
    width: 48%;
  }

  @media screen and (max-width: 860px) {
    width: 100%;
  }

  a {
    text-decoration: none;
  }
  .image {
    height: 304px;
    width: 100%;
    span {
      height: inherit !important;
      img {
        object-fit: cover;
        object-position: center;
      }
    }
  }

  div.whitebar {
    position: absolute;
    width: 120%;
    height: 400px;
    background: #fff;
    left: -2%;
    top: 265px;
    transform: rotate(-4.87deg);
  }

  .content {
    position: relative;
    top: -20px;
    padding: 0 31px 21px;
    .extra {
      text-align: right;
      svg {
        vertical-align: middle;
        display: inline-block;
        margin: 0 9px;
      }
    }
    .title {
      margin-top: 12px;
    }
    > svg {
      position: absolute;
      right: 31px;
      bottom: 21px;
      width: 18px;
      transform: scale(1.5);
      path {
        stroke: 'none';
        fill: ${black};
      }
    }
  }

  .overlay {
    position: absolute;
    width: 150%;
    height: 400px;
    bottom: -65px;
    left: -25%;
    right: -25%;
    background: ${(props) => props.hoverColor};
    opacity: 0.9;
    background-blend-mode: darken;
    transform: translateY(100%) rotate(-4.87deg);
    transition: 0.5s ease-in-out;
    .text {
      position: absolute;
      top: 30%;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      transform: rotate(4.87deg);
      svg {
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        path {
          stroke: none;
          fill: ${white};
        }
      }
    }
  }
  :hover,
  :focus {
    .overlay {
      transform: translateY(0) rotate(-4.87deg);
    }
  }

  // Mobile
  @media screen and (max-width: 400px) {
    .content {
      padding: 0 0px 21px;
      svg {
        display: none;
      }
    }
  }
`

const SubsiteNewsCard = ({
  title,
  category,
  date,
  url,
  img,
  search_img,
  showCategory = true,
  all = true,
}) => {
  const theme = useTheme()
  const primaryColor =
    theme.globalTheme?.palette?.primaryCustom?.main || 'rgba(46, 132, 42, 1)'
  const image = search_img || img
  return (
    <Card hoverColor={primaryColor} small={!all}>
      <Link href={url}>
        <div className="image">
          <ImageBuilder image={image} layout="fill" />
        </div>
        <div className="whitebar"></div>
        <div className="content">
          <div className="extra">
            {category && (
              <TypographyElement variant="newsCardCategory">
                {category}
              </TypographyElement>
            )}
            {showCategory && (
              <>
                {category && <SvgElement variant="textdivider" />}
                <TypographyElement
                  variant="newsCardDate"
                  color={all && secondary_green}
                >
                  {getMomentTz(date).format('DD-MM-YYYY')}
                </TypographyElement>
              </>
            )}
          </div>
          <div className="title">
            <TypographyElement variant={'newsCardTitleElipsis'}>
              {title}
            </TypographyElement>
          </div>
          <ArrowForwardIcon />
        </div>
        <div className="overlay">
          <div className="text">
            <TypographyElement variant="readMoreWhite">
              Lees meer
            </TypographyElement>
            <ArrowForwardIcon />
          </div>
        </div>
      </Link>
    </Card>
  )
}

export default SubsiteNewsCard
