import React from 'react'
import styled from 'styled-components'

import SuccessBlock from '../../compositions/succesBlock'
import ButtonElement from '../../elements/buttons'
import WidthContainer from '../../elements/widthContainer'

const Wrapper = styled.div`
  .buttonContainer {
    display: block;
    text-align: right;
    margin-top: 45px;
  }
`

const SuccessSection = ({
  successTitle = 'Evenement succesvol aangemeld!',
  successText = 'Het evenement is succesvol aangemeld. We zullen het evenement beoordelen en binnen 2 dagen ontvangt u een e-mail met goedkeuring',
  backTo = {
    text: 'Terug naar alle evenementen',
    href: '/evenementen',
  },
}) => {
  return (
    <WidthContainer variant="semi">
      <Wrapper>
        <SuccessBlock title={successTitle} text={successText} />
        {backTo && (
          <div className="buttonContainer">
            <ButtonElement variant="return" href={backTo?.href}>
              {backTo?.text}
            </ButtonElement>
          </div>
        )}
      </Wrapper>
    </WidthContainer>
  )
}

export default SuccessSection
