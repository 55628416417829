'use client'
import React from 'react'
import styled from 'styled-components'

import OpeningTimesBlock from '../../../compositions/_subsite/openingtimes'
import OpeningtimesFilter from '../../../compositions/_subsite/openingtimesFilter'
import WeekSelector from '../../../compositions/_subsite/weekSelector'
import TypographyElement from '../../../elements/typography'
import { getMomentTz } from '../../../utils/getMomentTz'

const Wrapper = styled.div`
  max-width: 974px;
  margin-bottom: 60px;
  .weekContainer {
    text-align: center;
  }
`

const OpeningTimesModule = ({
  whiteBackground = false,
  openingtimes = [],
  getOpeningtimes = () => {},
  categories = [],
  isActivities = false,
}) => {
  const today = getMomentTz()
  const [loading, setLoading] = React.useState(false)
  const [week, setWeek] = React.useState({
    start: today.startOf('week').toString(),
    end: today.endOf('week').toString(),
  })

  const handleChange = async (newWeek, category = null) => {
    if (loading) return null
    setLoading(true)
    setWeek(newWeek)
    await getOpeningtimes(newWeek?.start, category)
    setLoading(false)
  }

  let weekEmpty = !openingtimes?.some((item) => item?.items.length)
  const now = getMomentTz()
  return (
    <Wrapper>
      <div className="weekContainer">
        <WeekSelector week={week} handleChange={handleChange} />
      </div>
      <OpeningtimesFilter
        handleFilter={getOpeningtimes}
        filters={categories}
        week={week}
      />
      {!weekEmpty &&
        openingtimes?.map((item, i) => {
          const dateObj = getMomentTz(item?.datum, 'DD-MM-YYYY')
          if (dateObj.isBefore(now, 'day')) {
            return
          }
          return (
            <OpeningTimesBlock
              startOpen={dateObj.isSame(now, 'day') || i == 0}
              date={item.datum}
              timeslots={item.items}
              key={item.datum}
              whiteBackground={whiteBackground}
              isActivities={isActivities}
            />
          )
        })}
      {weekEmpty && (
        <TypographyElement variant="schemaResultTitle" component="span">
          Er zijn geen openingstijden gevonden voor deze week
        </TypographyElement>
      )}
    </Wrapper>
  )
}

export default OpeningTimesModule
