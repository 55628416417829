import React from 'react'
import styled from 'styled-components'
import { SubsiteContactInfoItem } from '../../../elements/listitem'

import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import EmailIcon from '@mui/icons-material/Email'
import { primary_orange, white } from '../../../colors'

const Wrapper = styled.div`
  .iconWrapper {
    padding: 5px;
    border-radius: 50%;
    background-color: ${white};
    line-height: 12px;
    margin-right: 12px;
  }
`

const ContactInfo = ({ telephone, email }) => {
  const phoneIcon = (
    <div className="iconWrapper">
      <LocalPhoneIcon style={{ color: primary_orange }} />
    </div>
  )

  const mailIcon = (
    <div className="iconWrapper">
      <EmailIcon style={{ color: primary_orange }} />
    </div>
  )

  return (
    <Wrapper>
      {telephone && (
        <SubsiteContactInfoItem
          text={telephone}
          href={`tel:${telephone.replaceAll('-', '').replaceAll(' ', '')}`}
          component="a"
          icon={phoneIcon}
        />
      )}
      {email && (
        <SubsiteContactInfoItem
          text={email}
          href={`mailto:${email}`}
          component="a"
          icon={mailIcon}
        />
      )}
    </Wrapper>
  )
}

export default ContactInfo
