'use client'
import React from 'react'
import styled from 'styled-components'
import { subsite_background } from '../../colors'
import { SectionElement } from '../../compositions/articleSection'
import ButtonElement from '../../elements/buttons'

import WidthContainer from '../../elements/widthContainer'
import OpeningTimesModule from '../../modules/_subsite/openingtimes'
import SubsiteProductPricesTable from '../../modules/_subsite/productPricesTable'

import { innerHtml } from '../../utils/innerHtml'
import ArticleRichText from '../articleRichText'

const Wrapper = styled.div`
  ${(props) =>
    props.multi &&
    `
        padding: 59px 113px 18px;

        @media screen and (max-width: 850px) {
            padding: 30px 60px 18px;
        }

        @media screen and (max-width: 550px) {
            padding: 30px 30px 18px;
        }

        @media screen and (max-width: 450px) {
            padding: 20px 20px 18px;
        }

        background-color: ${subsite_background}
    `};
  .contentBlock {
    margin-bottom: 80px;
    &.section,
    &.text {
      margin-bottom: 33px;
    }
  }
`

const TextBlock = ({ text }) => {
  return <div>{innerHtml(text)}</div>
}

const mapBlocks = {
  text: ArticleRichText,
  section: SectionElement,
  prices: SubsiteProductPricesTable,
  openingtimes: OpeningTimesModule,
}

const SingleItem = ({ item, selected, multi }) => {
  if (!selected) return null
  return (
    <>
      {item.content?.map((item, index) => {
        const Section = mapBlocks[item.type]
        return (
          <div
            key={`mainpageinfo-${index}`}
            className={`contentBlock ${item.type}`}
          >
            <Section {...item.content} whiteBackground={multi} />
          </div>
        )
      })}
    </>
  )
}

const TabWrapper = styled.div`
  display: inline-block;
  vertical-align: bottom;
  padding: 9px 33px;
  background-color: ${(props) =>
    props.selected ? subsite_background : '#C8E3EC'};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 200%;
`

const TabButton = ({ title, index, selected, setSelected }) => {
  const handleClick = () => {
    setSelected(index)
  }

  return (
    <ButtonElement
      variant="blank"
      onClick={handleClick}
      sx={{
        padding: 0,
        margin: 0,
        border: 0,
        borderRadius: 0,
      }}
    >
      <TabWrapper selected={selected == index}>{title}</TabWrapper>
    </ButtonElement>
  )
}

const MainInfo = ({ infoItems }) => {
  const [selected, setSelected] = React.useState(1)
  const single = infoItems.length <= 1

  return (
    <WidthContainer variant="semi">
      {!single &&
        infoItems?.map((item, i) => {
          return (
            <TabButton
              title={item.title}
              index={i + 1}
              key={`tab-${i}-${item.title}`}
              selected={selected}
              setSelected={setSelected}
            />
          )
        })}
      <Wrapper multi={!single}>
        {infoItems?.map((item, i) => {
          return (
            <SingleItem
              item={item}
              key={i}
              selected={i + 1 == selected}
              multi={!single}
            />
          )
        })}
      </Wrapper>
    </WidthContainer>
  )
}

export default MainInfo
