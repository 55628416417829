'use client'
import React from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material'

import SubsiteNewsCard from '../../compositions/_subsite/newsCard'
import WidthContainer from '../../elements/widthContainer'
import TypographyElement from '../../elements/typography'

const Container = styled.div`
  background: ${(props) => props.background};
  width: 100%;
  margin-bottom: 70px;
  .news {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    gap: 2%;
  }
  .button {
    text-align: right;
    > span.linkWrapper {
      width: 100%;
    }
    a {
      text-align: center;
    }

    @media screen and (max-width: 600px) {
      svg {
        display: none;
      }
    }
  }
`

const SubsiteShortNewsSection = ({ hasBackground = false, news, title }) => {
  const theme = useTheme()

  return (
    <WidthContainer variant="semi">
      <Container
        background={
          hasBackground
            ? theme?.globalTheme?.palette?.backgroundColor?.main
            : 'transparent'
        }
      >
        <TypographyElement variant="moreTitle" component="h2">
          {title}
        </TypographyElement>
        <div className="news">
          {news?.map((item, index) => {
            return (
              <SubsiteNewsCard
                key={`subsite-short-news-${index}`}
                {...item}
                date={item?.date || item?.last_published_at}
                all={false}
              />
            )
          })}
        </div>
      </Container>
    </WidthContainer>
  )
}

export default SubsiteShortNewsSection
