import React from 'react'
import { Radio, RadioGroup, FormLabel, FormControlLabel } from '@mui/material'
import styled from 'styled-components'

const Wrapper = styled.div`
  margin-bottom: 20px;
  background-color: #dce5ea;
  padding: 10px;
  line-height: 1;
  max-width: 974px;

  @media screen and (max-width: 700px) {
    margin: 0px -20px 20px;
    width: calc(100% + 40px);
  }
`

const OpeningtimesFilter = ({ filters, handleFilter, week }) => {
  const [selected, setSelected] = React.useState('all')

  const handleChange = (e) => {
    const value = e.target.value
    setSelected(value)
    handleFilter(week.start, value)
  }

  if (!filters?.length || filters?.length == 1) {
    return null
  }

  return (
    <Wrapper>
      <FormLabel variant="openingtimesFilters" id="openingtimes_filters">
        Filter op activiteiten:
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="openingtimes_filters"
        name="openingtimes_filters"
        variant="openingtimesFilters"
        value={selected}
        onChange={handleChange}
      >
        <FormControlLabel
          value="all"
          control={<Radio />}
          label="Alle activiteiten"
          key="radio-alle-activiteiten"
        />
        {filters?.map((filter, i) => {
          return (
            <FormControlLabel
              value={filter.slug}
              control={<Radio />}
              label={filter.title}
              key={`radio-${i + 1}-${filter.slug}`}
            />
          )
        })}
      </RadioGroup>
    </Wrapper>
  )
}

export default OpeningtimesFilter
