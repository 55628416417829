import React from 'react'
import Cookies from 'js-cookie'

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

/**
 * At startup, verifies that CsrfToken is there
 */
const getCSRFToken = async (host) => {
  const token = Cookies.get('csrftoken')
  if (token) {
    return token
  }
  let url = `${host}/api/csrftoken/`

  const res = await fetch(url, {
    method: 'post',
    headers: headers,
    credentials: 'same-origin',
  })
  const data = await res.json()
  return data.token
}

const CSRFToken = () => {
  const [csrf, setCsrf] = React.useState('')
  React.useEffect(() => {
    let host = window?.location?.origin
    if (host.includes('localhost:3000')) {
      host = host.replace('localhost:3000', 'localhost:8000')
    }
    getCSRFToken(host, setCsrf).then((token) => {
      setCsrf(token)
    })
  }, [])

  return <input type="hidden" name="csrfmiddlewaretoken" value={csrf} />
}

export default CSRFToken
